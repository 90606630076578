<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a :href="downloadURL">Download</a>
          </li>
          <!-- @click="gotodownload()" -->
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="blogs">Blogs</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <div class="language-selector">
              <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
                <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                  {{ data.val }}
                </option>
              </select>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #2677f2 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      GBWhatsApp
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.20</strong> | Size: <strong>77MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      GBWhatsApp is a customized version of the official WhatsApp, enhanced with additional features and privacy options. Designed for Android users, GBWhatsApp APK is available for free download and offers advanced capabilities beyond the standard app. These include enabling "ghost mode" for added privacy, customizing themes and fonts, sending high-resolution images, setting auto-reply messages similar to WhatsApp Business, and other premium features for a more personalized messaging experience.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <a class="wp-block-button__link wp-element-button" @click="downloadClick">Download GB WhatsApp
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <div id="toc_container" class="no_bullets">
                  <ul class="toc_list">
                    <li>
                      <a href="#TBC_0"><span class="toc_number toc_depth_1">1</span> What is GB WhatsApp?</a>
                    </li>
                    <li>
                      <a href="#TBC_1"><span class="toc_number toc_depth_1">2</span> You need the official WhatsApp to log in</a>
                    </li>
                    <li>
                      <a href="#TBC_2"><span class="toc_number toc_depth_1">3</span> Updates since the original Ban wave</a>
                    </li>
                    <li>
                      <a href="#TBC_3"><span class="toc_number toc_depth_1">4</span> GB WhatsApp Features</a>
                    </li>
                    <li>
                      <a href="#TBC_4"><span class="toc_number toc_depth_1">5</span> GB WhatsApp vs Official WhatsApp</a>
                    </li>
                    <li>
                      <a href="#TBC_5"><span class="toc_number toc_depth_1">6</span> Backup or restore GB WhatsApp data</a>
                    </li>
                    <li>
                      <a href="#TBC_6"><span class="toc_number toc_depth_1">7</span> GB WhatsApp old versions</a>
                    </li>
                    <li>
                      <a href="#TBC_7"><span class="toc_number toc_depth_1">8</span> Any working GB WhatsApp Alternatives</a>
                    </li>
                    <li>
                      <a href="#TBC_8"><span class="toc_number toc_depth_1">9</span> GBWhatsApp FAQ</a>
                    </li>
                    <li>
                      <a href="#TBC_9"><span class="toc_number toc_depth_1">10</span> Last Words</a>
                    </li>
                  </ul>
                </div>

                <h2 class="h">
                  <span id="TBC_0">
                    What is GB WhatsApp?
                  </span>
                </h2>

                <p>
                  GB WhatsApp is a modified version of the popular messaging app, WhatsApp. Known for its unique features and customizations, GB WhatsApp allows users to access functions not available in the official app. These features include hiding read receipts, concealing online status, scheduling messages, viewing deleted messages, and more. This added flexibility has made it popular among users seeking greater control and personalization of their messaging experience.
                </p>

                <p>
                  However, due to its unofficial status, GB WhatsApp faces compatibility and security challenges, including periodic ban waves that restrict its usage.
                </p>

                <figure class="wp-block-table is-style-regular">
                  <table class="has-fixed-layout">
                    <tbody>
                      <tr>
                        <th>App Info</th>
                        <th>GB WhatsApp</th>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>77M</td>
                      </tr>
                      <tr>
                        <td>Version</td>
                        <td>
                          <div class="code-block code-block-3" style="margin: 8px 0; clear: both">
                            v18.20
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Developer</td>
                        <td>AlexMODS</td>
                      </tr>
                      <tr>
                        <td>Last Updated</td>
                        <td>2 Days Ago</td>
                      </tr>
                      <tr>
                        <td>Total Downloads</td>
                        <td>2,000,000+</td>
                      </tr>
                      <tr>
                        <td>Rate</td>
                        <td>⭐⭐⭐⭐</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_1">
                    You Need the Official WhatsApp to Log In
                  </span>
                </h2>
                <p>
                  If you see a message saying, "You need the official WhatsApp to log in," you may be experiencing issues related to the recent ban wave. Here are a few potential solutions:
                </p>
                <ul>
                  <li><strong>- Try the Latest Version of GB WhatsApp: </strong>Downloading the newest version of GB WhatsApp may help, as it often includes fixes and updated anti-ban measures. However, this solution is not always effective.</li>
                  <li><strong>- Switch to the Official WhatsApp: </strong>If the issue persists, consider switching back to the official WhatsApp app. You'll likely need to re-register with your phone number.</li>
                  <li><strong>- Change Your Phone Number: </strong>If you're unable to access WhatsApp services using your current number, obtaining a new number might resolve the issue.</li>
                  <li><strong>- Reddit Group Advice: </strong>Some users in <a href="https://www.reddit.com/r/GBWhatsapp/" rel="nofollow" target="_blank" class="jump-url">GB WhatsApp Reddit</a> communities recommend advanced methods like rooting your phone. However, this approach has no guarantee of success and could void your phone's warranty.</li>
                </ul>

                <figure class="aligncenter size-full size-30">
                  <picture>
                    <source srcset="../assets/login-issue.webp" type="image/webp">
                    <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/login-issue.webp" alt="login issue" class="wp-image-48">
                  </picture>
                </figure>

                <h2 class="h">
                  <span id="TBC_2">
                    Updates since the original Ban Wave
                  </span>
                </h2>

                <p>
                  Following the recent ban wave, GB WhatsApp's developers have released updates aimed at addressing key issues, including:
                </p>
                <ul>
                  <li><strong>- Bug Fixes: </strong>Improvements have been made to resolve minor bugs and glitches, enhancing app stability.</li>
                  <li><strong>- Enhanced Anti-Ban Mechanism: </strong>Developers have strengthened the app's anti-ban protection to reduce the likelihood of bans, though no solution is foolproof. Frequent updates may help minimize the risk of account restrictions.</li>
                </ul>
                <p>
                  To stay protected, always ensure you're using the latest version of GB WhatsApp.
                </p>

                <h2 class="h">
                  <span id="TBC_3">
                    Screenshot
                  </span>
                </h2>
                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/Screenshot.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/Screenshot.webp" alt="Screenshot" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_4"> GB WhatsApp Features </span>
                </h2>

                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Ghost Mode</h3>
                    <p>Keep your activities private by hiding when you view contacts' status updates, enhancing your discretion.</p>

                    <h3 class="h3">Hide Online Status</h3>
                    <p>Remain invisible to your contacts while using the app, so others can't see when you're active.</p>

                    <h3 class="h3">Hide Second Ticks</h3>
                    <p>Read messages without notifying the sender by hiding the blue ticks that show a message has been read.</p>

                    <h3 class="h3">Hide Typing Indicators</h3>
                    <p>Type messages without displaying the "typing…" notification, adding an extra level of privacy.</p>

                    <h3 class="h3">DND Mode</h3>
                    <p>Temporarily disable internet access for GB WhatsApp only, silencing messages and notifications to focus on other tasks.</p>

                    <h3 class="h3">Lock App</h3>
                    <p>Secure GB WhatsApp with a passcode, fingerprint, or pattern lock, protecting your chats from unauthorized access.</p>

                    <h3 class="h3">Who can call me</h3>
                    <p>Limit video calls by setting restrictions, blocking calls from specific contacts.</p>
                    <h3 class="h3">Change Fonts</h3>
                    <p>Choose from various font styles to personalize your messaging experience to your taste.</p>

                  </div>

                  <div class="is-layout-flow wp-block-column">

                    <h3 class="h3">4,000+ Themes</h3>
                    <p>Access a wide selection of themes to change backgrounds, colors, and layout, giving the app a unique and personalized look.</p>

                    <h3 class="h3">Change App Icon</h3>
                    <p>Modify the appearance of the GB WhatsApp icon on your home screen for a more customized or discreet look.</p>

                    <h3 class="h3">Download Status Videos</h3>
                    <p>All GB WhatsApp users can save status videos directly to their Android phone, no third-party app needed.</p>

                    <h3 class="h3">Auto-Reply Messages</h3>
                    <p>Set up automatic responses to specific messages, perfect for when you're unavailable or managing business inquiries.</p>

                    <h3 class="h3">Schedule Messages</h3>
                    <p>Schedule messages to be sent at a specific time, ideal for reminders, greetings, or timely responses.</p>

                    <h3 class="h3">Unread Messages</h3>
                    <p>Easily keep track of unread messages, even after opening the chat, ensuring important conversations are not missed.</p>

                    <h3 class="h3">Go to the First Message</h3>
                    <p>Quickly navigate back to the first message in any chat, convenient for revisiting the start of a conversation without scrolling.</p>

                    <h3 class="h3">Anti-Revoked Messages</h3>
                    <p>View messages even after they're deleted by the sender, with an indication showing which messages were removed.</p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_5">
                    GB WhatsApp vs official WhatsApp
                  </span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>GB WhatsApp</th>
                        <th>Official WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ghost Mode</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Anti-Revoked Messages</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>High Resolution Images</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Change Blueticks Icon</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>App Lock</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Themes</td>
                        <td>4,000+</td>
                        <td>default</td>
                      </tr>
                      <tr>
                        <td>Change Fonts</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Save Status</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Freeze Last Seen</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>File Sharing Limits</td>
                        <td>2G</td>
                        <td>2G</td>
                      </tr>
                      <tr>
                        <td>Supported System</td>
                        <td>Android</td>
                        <td>Android, iOS, Desktop, Web</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_6"> Backup or restore GB WhatsApp data </span>
                </h2>
                <p>
                  If you decide to switch between GB WhatsApp and the official WhatsApp or need to reinstall GB WhatsApp, data backup and transfer are essential.
                </p>
                <ul>
                  <li><strong>1. Switch Back to WhatsApp: </strong>If you're moving from GB WhatsApp to the official WhatsApp app:
                    <ul>
                      <li> - Go to GB WhatsApp settings and back up your chat data.
                        <br>
                        - Install the official WhatsApp app, verify your number, and restore your chat backup when prompted.
                      </li>
                    </ul>
                  </li>
                  <li><strong>2. Restore WhatsApp Data to GB WhatsApp: </strong>If you're switching back to GB WhatsApp from WhatsApp:
                    <ul>
                      <li> - Back up your WhatsApp data.
                        <br>
                        - Reinstall GB WhatsApp, and during setup, restore your chat data to retain your messages and media.
                      </li>
                    </ul>
                  </li>
                </ul>

                <h2 class="h">
                  <span id="TBC_7"> GB WhatsApp old versions </span>
                </h2>
                <p>
                  If you're experiencing issues with GB WhatsApp, check if you're using an outdated version. Older versions may not work due to compatibility and security updates from WhatsApp.
                </p>
                <ul>
                  <li>- GBWhatsApp v18.10</li>
                  <li>- GBWhatsApp v18.00</li>
                  <li>- GBWhatsApp v17.85</li>
                  <li>- GB WhatsApp 17.80</li>
                  <li>- GB WhatsApp 17.76</li>
                </ul>
                <p>
                  Download the latest version of GB WhatsApp from a trusted source to ensure you have the most recent anti-ban and feature updates. Frequent updates help improve functionality and security, so it's essential to keep your app updated.
                </p>

                <h2 class="h">
                  <span id="TBC_8">
                    Any working GB WhatsApp alternatives
                  </span>
                </h2>
                <p>
                  For users looking to explore similar features, there are several alternatives to GB WhatsApp that offer comparable customization and functionality. Here are a few:
                </p>
                <ul>
                  <li><strong>- FM WhatsApp: </strong>Known for its unique privacy features and aesthetic customizations.</li>
                  <li><strong>- WhatsApp Plus: </strong>Offers extensive theme options and advanced message privacy controls.</li>
                  <li><strong>- GB WhatsApp Pro: </strong>A version similar to GB WhatsApp but with additional security enhancements.</li>
                  <li><strong>- YoWhatsApp: </strong>Emphasizes customization and privacy settings, like hiding last seen.</li>
                  <li><strong>- OBWhatsApp, JTWhatsApp, OGWhatsApp: </strong>Each of these mods has its own set of features, from custom themes to extended message options.</li>
                </ul>
                <p>
                  Remember, while these alternatives provide added features, they also carry the risk of being unsupported by official WhatsApp policies and could result in account restrictions.
                </p>

                <h2 class="h">
                  <span id="TBC_8"> GBWhatsApp FAQ: </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Q: Can GB WhatsApp Work on iPhone? </strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      A: No, there is no official GB WhatsApp iOS version. You'd better switch to WhatsApp.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Q: Can GB WhatsApp Work on PC? </strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      A: Yes. You can download Android Emulator like Bluestacks on your PC, then install GB WhatsApp APK. 
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Q: Should I update GB WhatsApp frequently?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      A: If the developer releases a new version, you'd better to update GB WhatsApp old version. The new version can help you protect your account. 
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Q: Who is the GB WhatsApp APK Developer?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      A: There are several teams focusing on updating GBWhatsApp. You may search out AndroidWave GB WhatsApp, Heymods GB WhatsApp, Alexmods GBWhatsApp.
                    </p>
                  </div>
                </div>


                <h2 class="h">
                  <span id="TBC_9"> Last Words </span>
                </h2>
                <p>
                  Downloading the latest version of GB WhatsApp and exploring alternatives can offer added features, but it's essential to stay informed of the account banning risks involved. For a seamless experience, keep your app updated and be mindful of security measures to enjoy your favorite features with fewer interruptions.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="comment-box">
    <div class="title">
      Leave a Comment
    </div>
    <input v-model="comment.name" type="text" class="nameClass" placeholder="your name">
    <input v-model="comment.email" type="email" class="emailClass" placeholder="Email">
    <textarea v-model="comment.content" class="contentClass" />
    <button class="submitClass" @click="submitClick">
        Submit
      </button>
      <div class="commentList-box">
        <div v-for="item in showCommentList" :key="item.time" class="commentClass">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle" />
          </div>
        </div>
      </div>
      <!-- <button class="moreClass" @click="moreClick">
        {{ showText }}
      </button> -->
    </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList"
              href="/" class="jump-url">https://gbapks.com.ru/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="downloadClick()">
      Download GBWhatsApp New
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
export default {
  name: 'Home',
  data() {
    return {
      pageName: 'home',
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'ar',
          val: '🇦🇪 اَلْعَرَبِيَّةُ'
        },
        {
          lang: 'id',
          val: '🇮🇩 Bahasa Indonesia'
        },
        {
          lang: 'po',
          val: '🇵🇹 Português'
        },
        {
          lang: 'tr',
          val: '🇹🇷 Türkçe'
        },
        {
          lang: 'in',
          val: '🇮🇳 हिंदी'
        },
      ],
    };
  },
  computed: {
    allCommentList() {
      const regularTime = Date.parse('2023/11/24 00:00:00');
      let currentTime = new Date().getTime();
      let Difference = Math.floor((currentTime - regularTime) / (3 * 24 * 60 * 60 * 1000));
      return allCommentList.slice(0, 20 + Difference).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) || [];
    },
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/gbwhatsapp-downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/gbwhatsapp-downloadpage${params}`;
      }
    },
    showText() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return 'Click Here To Load More Comments';
      } else {
        return 'No More Comments';
      }
    },
    showCommentList() {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 3) || [];
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    moreClick() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    submitClick() {
      this.$Dialog
        .confirm({
          title: 'Tip',
          message: 'Your comment has been submitted successfully and will be displayed below after review.',
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
        })
        .then(() => {
          this.comment.name = '';
          this.comment.email = '';
          this.comment.content = '';
        })
        .catch(() => {
          console.log('取消');
        });
    },
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
